import {
  Box,
  BoxProps,
  ChakraProvider,
  extendTheme,
  Flex,
  Stack,
  useDisclosure,
} from '@chakra-ui/react'
import React, { useMemo, useState } from 'react'
import { routes, useURL } from 'src/constants/routes'
import { Contact } from './components/Contact'
import { FAQList } from './components/FAQList'
import { Features } from './components/Features'
import { Footer } from '../Organisms/Footers/GeneralFooter'
import {
  Header,
  HEADER_HEIGHT_BREAKPOINT,
} from '../Organisms/Headers/GeneralHeader'
import { SignupCard, ModalSignupCard } from './components/Signup'
import { MainVisual } from './components/MainVisual'
import { Pop } from './components/Pop'
import { Pricing } from './components/Pricing'
import { UseSceneList } from './components/UseSceneList'
import { theme } from './theme'
import { Background } from './components/Background'
import { App } from 'src/api/firebase'
import { ITEMS, ITEM_LISTS } from 'src/api/analytics'
import { DisplayPlan } from '@gijirokukun/shared'
import { SignupButton } from '../Organisms/Form/SignUpForm'

// FIXME: fontの太さがfigmaと違う
// FIXME: 右のフォームのせいでブレークポイントの単調増加性が壊れてしまっているのをどうにかする

const customTheme = extendTheme(theme)

export const FRAME_BREAKPOINT = [
  '1.25rem',
  '1.75rem',
  '2.125rem',
  '3.5rem',
  '5rem',
]
export const SIGNUP_CARD_WIDTH_BREAKPOINT = { base: '0rem', xl: '20rem' }

export const Frame: React.FC<BoxProps> = (props) => (
  <Box px={FRAME_BREAKPOINT} {...props}>
    {props.children}
  </Box>
)

export const LandingInner = () => {
  const { toURL } = useURL()

  const {
    isOpen: isOpenModalSignupOpen,
    onOpen: openModalSignup,
    onClose: closeModalSignup,
  } = useDisclosure()

  const [selectedPlan, setSelectedPlan] = useState<DisplayPlan>(
    new DisplayPlan('PersonalStandard')
  )

  const onClickPlan = (plan: DisplayPlan) => {
    setSelectedPlan(plan)
    App.analytics.logEvent('select_item', {
      ...ITEM_LISTS['LANDING_PLAN_LIST'],
      items: [ITEMS[plan.subscriptionType]],
    })

    App.analytics.logEvent('landing_plan_clicked', {
      planV2Type: plan.serialize(),
    })
    openModalSignup()
  }

  const signupRedirectLink = useMemo(() => {
    if (!toURL) return
    return selectedPlan.getPurchaseLink((route) => toURL(route))
  }, [toURL, selectedPlan])

  return (
    <>
      <Flex w="full" flexDir="row">
        <Stack w={{ base: '100%', xl: 'calc(100% - 20rem)' }} spacing={0}>
          <Header w={{ base: '100%', xl: 'calc(100% - 20rem)' }} />
          <Flex h={HEADER_HEIGHT_BREAKPOINT} />
          <ModalSignupCard
            isOpen={isOpenModalSignupOpen}
            handleClose={closeModalSignup}
            selectedPlan={selectedPlan}
            callbackURL={signupRedirectLink}
          />
          <Box display={{ base: 'block', xl: 'none' }}>
            <SignupButton onClick={openModalSignup} />
          </Box>
          <MainVisual />
          <Box pos="relative" overflow="hidden">
            <Background />
            <Pop />
          </Box>
          <UseSceneList />
          {/* // サンプル動画はまだないので。
          <SampleMovie />
           */}
          <Features />
          <Pricing onClick={onClickPlan} />
          {/* // 導入企業はまだないので。このコンポーネントは不完全なので使う場合は修正が必要
          <ListOfIntroducedCompanies />
          */}
          <FAQList />
          <Contact />
          <Box pt="5rem" />
          <Footer />
        </Stack>
        <Box
          display={{ base: 'none', xl: 'block' }}
          boxShadow={`-0.125rem 0rem 0rem ${theme.colors.lightGray}`}
          pos="fixed"
          right="0"
          h="100vh"
          w="20rem"
          zIndex="sticky"
          overflowY="auto"
          overflowX="hidden"
          bg="white"
        >
          <SignupCard
            isModal={false}
            selectedPlan={new DisplayPlan('PersonalStandard')}
            callbackURL={signupRedirectLink}
          />
        </Box>
      </Flex>
    </>
  )
}

export const Landing = () => {
  return (
    <ChakraProvider theme={customTheme}>
      <LandingInner />
    </ChakraProvider>
  )
}
